<template>
  <div>
    <Table
      :actions="actions"
      :items="$store.state.wallet.tableData"
      :fields="fields"
      :keyword="$store.state.user.tableKeyword"
      :loading="$store.state.wallet.loadingTable"
      :filter="$store.state.wallet.tableFilter"
      :sort="$store.state.wallet.tableSort"
      :pagination="$store.state.order.tablePagination"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      rejectionModalVisible: false,
      refundModalVisible: false,

      selectedData: '',

      // Tabs
      tabOptions: [
        { key: 'type', label: 'All' },
        {
          key: 'type', label: 'Credit', value: 'credit', default: true,
        },
        { key: 'type', label: 'Earning', value: 'earning' },
      ],

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
          url: {
            callback(data) {
              return `/user-transaction/${data.id}`
            },
          },
        },
        {
          key: 'user.nickname',
          label: 'User',
          type: 'profile',
          image: 'user.avatar',
          sortable: false,
          url: {
            callback(data) {
              return `/user/${data.userID}`
            },
          },
        },
        {
          key: 'user.email',
          label: 'Email',
          type: 'email',
          sortable: false,
        },
        {
          key: 'user.profile.countryCode',
          type: 'country',
          label: 'Country',
          visibility: ['view'],
          filter: {
            key: 'countries',
            type: 'select',
            options: [
              { value: 'PH', text: 'Philippines' },
              { value: 'TH', text: 'Thailand' },
              { value: 'MY', text: 'Malaysia' },
              { value: 'SG', text: 'Singapore' },
              { value: 'ID', text: 'Indonesia' },
              { value: 'TW', text: 'Taiwan' },
            ],
          },
        },
        {
          key: 'balance',
          label: 'Balance',
          type: {
            callback(data) {
              return data.type === 'credit' ? 'gold' : undefined
            },
          },
          prefix: {
            callback(data) {
              return data.type === 'earning' ? '$' : undefined
            },
          },
        },
        {
          key: 'updatedAt',
          label: 'Last Updated',
          type: 'datetime',
        },
      ],

      actions: [
        'view',
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('wallet/getTableData')
    },
    filterData(val) {
      this.$store.commit('wallet/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('wallet/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('wallet/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('wallet/SET_TABLE_KEYWORD', val)
    },
  },
}
</script>
